<template>
  <v-card
    class="border-radius-l mt-5"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <v-row class="justify-space-between pt-3 pb-5 px-7">
      <v-col class="my-0">
        <div>
          <h5 class="text-h5 font-weight-bold text-typo">Übersicht</h5>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="justify-space-between pt-8 pb-5 px-7 text-center">
      <v-col cols="6" sm="3">
        <div style="cursor: pointer" @click="$router.push('/customers')">
          <img
            :src="require('@/assets/icons/soap-clients-icon-colored.svg')"
            width="25"
            height="25"
            contain
            alt="icon"
            class="font-weight-normal text-warning"
          />

          <div class="my-1 font-weight-bolder text-lg">
            {{ customers.data ? customers.data.length : 0 }}
          </div>
          <div class="text-s" v-if="customers.data">
            Kunde{{ customers.data.length > 1 ? "n" : "" }}
          </div>
        </div>
      </v-col>
      <v-col cols="6" sm="3">
        <div
          class="align-space-between"
          style="cursor: pointer"
          @click="$router.push('/projects')"
        >
          <img
            :src="
              require('@/assets/icons/soap-contractVolume-icon-cololored.svg')
            "
            width="25"
            height="25"
            contain
            alt="icon"
            class="font-weight-normal text-warning"
          />
          <div class="my-1 font-weight-bolder text-lg">
            {{ computedValue }}
          </div>
          <div class="text-s">Auftragsvolumen</div>
        </div>
      </v-col>
      <v-col cols="6" sm="3">
        <div style="cursor: pointer" @click="$router.push('/projects')">
          <img
            :src="require('@/assets/icons/soap-projects-icon-colored.svg')"
            width="25"
            height="25"
            contain
            alt="icon"
            class="font-weight-normal text-warning"
          />
          <div class="my-1 font-weight-bolder text-lg">
            {{ projects.data ? projects.data.length : 0 }}
          </div>
          <div class="text-s" v-if="projects.data">
            Projekt{{ projects.data && projects.data.length == 1 ? "" : "e" }}
          </div>
        </div> </v-col
      ><v-col cols="6" sm="3">
        <div style="cursor: pointer" @click="$router.push('/tasks')">
          <img
            :src="require('@/assets/icons/soap-tasks-icon-colored.svg')"
            width="25"
            height="25"
            contain
            alt="icon"
            class="font-weight-normal text-warning"
          />
          <div class="my-1 font-weight-bolder text-lg">
            {{ tasks }}
          </div>
          <div class="text-s">Aufgabe{{ tasks == 1 ? "" : "n" }}</div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Overview",
  computed: {
    computedValue() {
      const number = this.orderValue;
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(number);
    },
    orderValue() {
      if (this.projects && this.projects.data) {
        let value = 0;
        this.projects.data.forEach((element) => {
          value += element.orderValue;
        });
        return value;
      } else {
        return 0;
      }
    },
    tasks() {
      if (this.projects && this.projects.data) {
        let value = 0;
        this.projects.data.forEach((element) => {
          value += element.tasks.length;
        });
        return value;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
</style>