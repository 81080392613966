<template>
  <v-card class="border-radius-xl z-index-2">
    <div class="card-padding pa-5">
      <div class="chart">
        <canvas
          id="line-chart-projects"
          class="chart-canvas"
          height="300"
        ></canvas>
      </div>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "line-chart-projects",
  props: {
    selectedFilter: null,
    cumulative: false,
  },
  data: function () {
    return {
      lineChartId: "line-chart-projects",
      selectedPeriod: 2,
      customerGraphFilters: [
        { title: "Dieses Jahr", id: 1 },
        { title: "Dieser Monat", id: 2 },
        { title: "Diese Woche", id: 2 },
      ],
      myChart: null,
    };
  },
  computed: {
    dataEntries() {
      switch (this.selectedFilter) {
        case 1:
          let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          let dt = this.todaysDate;
          let month = dt.getMonth();

          try {
            this.projects.data.forEach((element) => {
              let tempDate = new Date(element.createdAt);
              let tempMonth = tempDate.getMonth();
              let tempYear = tempDate.getFullYear();
              if (tempYear == this.todaysDate.getFullYear())
                array[tempMonth] += 1;
            });
            if (this.cumulative)
              for (let index = 1; index < array.length; index++) {
                array[index] += array[index - 1];
              }
          } catch (error) {}
          array.splice(
            12 - (11 - this.todaysDate.getMonth()),
            11 - this.todaysDate.getMonth()
          );
          return array;

        case 2:
          return this.dataEntriesMonth;
        default:
          break;
      }
    },
    dataEntriesPreperiod() {
      switch (this.selectedFilter) {
        case 1:
          let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          try {
            this.projects.data.forEach((element) => {
              let tempDate = new Date(element.createdAt);
              let tempMonth = tempDate.getMonth();
              let tempYear = tempDate.getFullYear();
              if (tempYear == this.todaysDate.getFullYear() - 1)
                array[tempMonth] += 1;
            });
            if (this.cumulative)
              for (let index = 1; index < array.length; index++) {
                array[index] += array[index - 1];
              }
          } catch (error) {}

          return array;

        case 2:
          return this.dataEntriesMonthPreperiod;
        default:
          break;
      }
    },
    dataEntriesMonth() {
      let array = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      let dt = this.todaysDate;
      let month = dt.getMonth();
      try {
        this.projects.data.forEach((element) => {
          let tempDate = new Date(element.createdAt);
          let tempMonth = tempDate.getMonth();
          let tempYear = tempDate.getFullYear();

          if (tempMonth == month && tempYear == this.todaysDate.getFullYear()) {
            let tempDay = tempDate.getDate() - 1;
            array[tempDay] += 1;
          }
        });
        if (this.cumulative)
          for (let index = 1; index < array.length; index++) {
            array[index] += array[index - 1];
          }
      } catch (error) {}
      array.splice(
        31 - (31 - this.todaysDate.getDate()),
        31 - this.todaysDate.getDate()
      );
      return array;
    },
    dataEntriesMonthPreperiod() {
      let array = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      let dt = this.lastMonthDate;
      let month = dt.getMonth();
      let year = dt.getFullYear();
      try {
        this.projects.data.forEach((element) => {
          let tempDate = new Date(element.createdAt);
          let tempMonth = tempDate.getMonth();
          let tempYear = tempDate.getFullYear();
          if (
            tempMonth == month &&
            tempYear == this.lastMonthDate.getFullYear()
          ) {
            let tempDay = tempDate.getDate() - 1;
            array[tempDay] += 1;
          }
        });
        if (this.cumulative)
          for (let index = 1; index < array.length; index++) {
            array[index] += array[index - 1];
          }
      } catch (error) {}
      let daysAvailable = new Date(year, month, 0).getDate();
      array.splice(31 - (31 - daysAvailable), 31 - daysAvailable);
      return array;
    },
  },
  watch: {
    dataEntries: {
      handler: function (newValue) {
        this.buildChart();
      },
      deep: true,
    },
    projects: {
      handler: function (newValue) {
        this.buildChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.buildChart();
  },
  methods: {
    buildChart() {
      let label_1;
      let label_2;

      if (this.selectedFilter == 1) {
        label_1 = `Projekte ${this.todaysDate.getFullYear()}`;
        label_2 = `Projekte ${this.todaysDate.getFullYear() - 1}`;
      } else {
        label_1 = `Projekte ${this.months[this.todaysDate.getMonth()]}`;
        label_2 = `Projekte ${this.months[this.lastMonthDate.getMonth()]}`;
      }
      if (this.myChart) this.myChart.destroy();
      this.myChart = new Chart(
        document.getElementById(this.lineChartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.labels,
            datasets: [
              {
                label: "Projekte",
                tension: 0.4,
                pointRadius: 2,
                pointBackgroundColor: "#4CAF50",
                borderColor: "#4CAF50",
                borderWidth: 3,
                backgroundColor: "#4CAF50",
                data: this.dataEntries,
                maxBarThickness: 6,
              },
              {
                label: label_2,
                tension: 0.4,
                pointRadius: 2,
                pointBackgroundColor: "#b2b9bf",
                borderColor: "#b2b9bf",
                borderWidth: 3,
                backgroundColor: "#b2b9bf",
                data: this.dataEntriesPreperiod,
                maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "bottom",
                align: "end",
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                display: false,
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                  color: "#fff",
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#b2b9bf",
                  font: {
                    size: 14,
                    weight: 300,
                    family: "Montserrat",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                display: true,
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: true,
                  borderDash: [5, 5],
                  color: "#fff",
                },
                ticks: {
                  display: true,
                  color: "#b2b9bf",
                  padding: 10,
                  font: {
                    size: 14,
                    weight: 300,
                    family: "Montserrat",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        }
      );
    },
  },
  // data: function () {
  //   return {
  //     lineChartId: "line-chart-projects",
  //   };
  // },
  // mounted() {
  //   new Chart(document.getElementById(this.lineChartId).getContext("2d"), {
  //     type: "line",
  //     data: {
  //       labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  //       datasets: [
  //         {
  //           label: "Direct",
  //           tension: 0.4,
  //           pointRadius: 2,
  //           pointBackgroundColor: "#4CAF50",
  //           borderColor: "#4CAF50",
  //           borderWidth: 3,
  //           backgroundColor: "transparent",
  //           data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
  //           maxBarThickness: 6,
  //         },
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       plugins: {
  //         legend: {
  //           display: false,
  //         },
  //       },
  //       interaction: {
  //         intersect: false,
  //         mode: "index",
  //       },
  //       scales: {
  //         y: {
  //           display: false,
  //           grid: {
  //             drawBorder: false,
  //             display: true,
  //             drawOnChartArea: true,
  //             drawTicks: false,
  //             borderDash: [5, 5],
  //             color: "#fff",
  //           },
  //           ticks: {
  //             display: true,
  //             padding: 10,
  //             color: "#b2b9bf",
  //             font: {
  //               size: 14,
  //               weight: 300,
  //               family: "Montserrat",
  //               style: "normal",
  //               lineHeight: 2,
  //             },
  //           },
  //         },
  //         x: {
  //           display: false,
  //           grid: {
  //             drawBorder: false,
  //             display: true,
  //             drawOnChartArea: true,
  //             drawTicks: true,
  //             borderDash: [5, 5],
  //             color: "#fff",
  //           },
  //           ticks: {
  //             display: true,
  //             color: "#b2b9bf",
  //             padding: 10,
  //             font: {
  //               size: 14,
  //               weight: 300,
  //               family: "Montserrat",
  //               style: "normal",
  //               lineHeight: 2,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });
  // },
};
</script>
