<template>
  <div v-if="selectedType == 'provider'">
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12">
        <overview />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12" md="6">
        <customers />
      </v-col>
      <v-col cols="12" md="6">
        <order-volume />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12" md="6">
        <projects />
      </v-col>
      <v-col cols="12" md="6">
        <tasks />
      </v-col>
    </v-row>
  </div>
  <div v-else><ProjectDetails /></div>
</template>

<script>
import Overview from "./Widgets/Overview.vue";
import Customers from "./Widgets/Customers.vue";
import OrderVolume from "./Widgets/OrderVolume.vue";
import Projects from "./Widgets/Projects.vue";
import Tasks from "./Widgets/Tasks.vue";
import ProjectDetails from "./Widgets/ProjectDetails.vue";
export default {
  name: "Dashboard",
  components: {
    Overview,
    Customers,
    Projects,
    OrderVolume,
    Tasks,
    ProjectDetails,
  },
  data() {
    return {};
  },
};
</script>
<style>
.v-select__selection {
  width: 100%;
  justify-content: flex-end !important;
  text-align: end;
}

.v-select__selections input {
  /* display: none; */
}
</style>
