<template>
  <v-card
    class="border-radius-l mt-5"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <v-row class="justify-space-between pt-3 pb-5 px-7">
      <v-col>
        <div>
          <h5 class="text-h5 font-weight-bold text-typo">Kunden</h5>
        </div>
        <div v-if="growthPercentage || growthPercentage == 0">
          <span
            class="font-weight-normal"
            :class="growthPercentage <= 0 ? 'text-danger' : 'text-success'"
            >{{
              `${growthPercentage < 0 ? "" : "+"}${growthPercentage.toFixed(
                2
              )}%`
            }}</span
          >
        </div>
      </v-col>

      <v-col>
        <div>
          <v-select
            :items="customerGraphFilters"
            density="compact"
            hide-details=""
            flat
            solo
            item-text="title"
            item-value="id"
            v-model="selectedFilter"
          ></v-select>
        </div>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <div style="position: relative">
      <line-chart-customer
        :selectedFilter="selectedFilter"
        :cumulative="cumulative"
      ></line-chart-customer>
      <v-row
        class="pa-0 ma-0 ml-5"
        style="position: absolute; bottom: 10px; z-index: 5"
      >
        <v-list-item :ripple="false" class="px-0 border-radius-sm mb-3">
          <v-switch
            :ripple="false"
            class="d-inline-flex mt-0 pt-0 switch ms-auto"
            v-model="cumulative"
            hide-details
          ></v-switch>
          <v-list-item-content class="py-0">
            <div class="ms-4 text-body text-sm">Kumulativ</div>
          </v-list-item-content>
        </v-list-item>
      </v-row>
    </div>
  </v-card>
</template>
  
  <script>
import LineChartCustomer from "./Charts/LineChartCustomer.vue";
export default {
  name: "Customers",
  data: function () {
    return {
      cumulative: false,
      selectedFilter: 1,
      customerGraphFilters: [
        { title: "Dieses Jahr", id: 1 },
        { title: "Dieser Monat", id: 2 },
        // { title: "Diese Woche", id: 3 },
      ],
    };
  },
  components: { LineChartCustomer },
  computed: {
    growthPercentage() {
      if (this.customers.data)
        if (this.selectedFilter == 1) {
          let thisYearCounter = this.customers.data.filter((customer) => {
            let tempDate = new Date(customer.updatedAt);
            let tempYear = tempDate.getFullYear();
            if (tempYear == this.todaysDate.getFullYear()) {
              return true;
            }
          }).length;
          let lastYearCounter = this.customers.data.filter((customer) => {
            let tempDate = new Date(customer.updatedAt);
            let tempYear = tempDate.getFullYear();
            if (tempYear == this.todaysDate.getFullYear() - 1) {
              return true;
            }
          }).length;
          if (lastYearCounter == 0) {
            return 100;
          }
          return (100 * thisYearCounter) / lastYearCounter - 100;
        } else {
          let thisMonthCounter = this.customers.data.filter((customer) => {
            let tempDate = new Date(customer.updatedAt);
            let tempYear = tempDate.getFullYear();
            let tempMonth = tempDate.getMonth();
            if (
              tempMonth == this.todaysDate.getMonth() &&
              tempYear == this.todaysDate.getFullYear()
            ) {
              return true;
            }
          }).length;
          let lastMonthCounter = this.customers.data.filter((customer) => {
            let tempDate = new Date(customer.updatedAt);
            let tempYear = tempDate.getFullYear();
            let tempMonth = tempDate.getMonth();
            if (
              tempMonth == this.todaysDate.getMonth() - 1 &&
              tempYear == this.todaysDate.getFullYear()
            ) {
              return true;
            }
          }).length;
          if (lastMonthCounter == 0) {
            return 100;
          }
          return (100 * thisMonthCounter) / lastMonthCounter - 100;
        }
    },
  },
};
</script>
